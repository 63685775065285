<template>
  <div>
    <div class="text-sm text-gray-500 mb-10">
      Get started with updating your company profile. All fields are required.
    </div>

    <div class="">

      <form @submit.prevent="submit" novalidate class="card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16" key="admin">
        
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          left-icon="people-circle-outline"
          name="name"
          v-model="form.data.name.value"
          :form="form"
        >
          Name
        </form-group>

        <form-group
          type="textarea"
          left-icon="help-circle-outline"
          name="description"
          v-model="form.data.description.value"
          :form="form"
        >
          Description
        </form-group>

        <div class="flex flex-row items-center justify-end">
          <button
            class="text-sm text-gray-500 mr-10"
            @click.prevent="skip"
          >
            Skip for later
          </button>
          <button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
            <span v-if="form.loading">Submitting...</span>
            <span v-else>Next</span>
          </button>
        </div>

      </form>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$options.basicForm([
          'name',
          'description',
        ])
      }
    },
    computed: {
    },
    methods: {
      skip() {
        this.$emit('skip');
      },
      async getGroups(forced = false) {
        this.loadResource('groups', forced);
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = false;
        this.form.loading = true;

        await this.$post({
          url: `${this.$baseurl}/companies/groups`,
          data: this.getFormData(),
          headers: this.headers,
          success: () => {
            this.form = this.resetForm(this.form);
            this.$emit('success');
            this.getGroups(true);
          },
          error: error => {
            this.form.error = error;

            this.mapFormErrors(this.form, error?.response?.data?.errors);
          }
        });

        this.form.loading = false;
      }
    },
  }
</script>